import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { faro } from '@grafana/faro-web-sdk';
import { environment } from '../../../environments/environment';
import { AppConsts } from '../app-consts';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.startMonitoring();
    }

    private startMonitoring(): void {
        const instrumentationKey = environment[AppConsts.ApplicationInsightsConfigurationName].instrumentationKey;

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                enableAutoRouteTracking: true,
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();

        const telemetryInitializer = (envelope) => {
            envelope.tags['ai.cloud.role'] = AppConsts.ApplicationInsightsCloudRoleName;
        };

        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }

    logEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackEvent({ name: name }, properties);
        faro.api.pushEvent(name);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
        faro.api.pushMeasurement({
            type: name,
            values: {
              duration: average,
            },
          });    
        }

    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        faro.api.pushError(exception);
        console.error(exception);
    }

    logTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace({ message: message }, properties);
        faro.api.pushLog([message]);
    }
}